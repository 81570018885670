<template>
  <div class="home">
    <hgroup>
      <h1>Lee Nugent<span class="blink">|</span></h1>
      <h3>Engineer, Maker, Human.</h3>
    </hgroup>

    <footer>
      <span id="divider"></span>
      &copy;{{ new Date().getFullYear() }} <a href="https://leenug.com">Lee Nugent</a>. Hosted on AWS, from London with <img :src="heart" alt="love" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return {
      heart: require('../assets/heart.svg')
    }
  }
}
</script>

<style lang="scss">
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    overflow: hidden;
  }

  hgroup {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 75vh;
    h1 {
      font-size: 3.5em;
      font-weight: 100;
      line-height: .8em;
      span {
        color: lime;
      }
      margin: 0;
    }

    h3 {
      font-size: 1.5em;
      font-weight: 100;
      margin: 0;
    }
  }

  footer {
    text-align: center;
    line-height: 20px;
    min-height: 25vh;
    font-size: 1.1em;
    img {
      width: 15px;
    }
    a {
      text-decoration: none;
      color: #C1C1C1;
      border-bottom: 1px dotted lime;
    }
  }

  .blink {
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1,0,0,1);
    animation-duration: 1s;
  }

  @keyframes blinker {
    from { opacity: 1.0; } to { opacity: 0.0; }
  }

</style>

